import React from 'react';
import { Divider } from '@mui/material';
import WelcomeMessageEditor from './components/WelcomeMessageEditor';
import FailureMessageEditor from './components/FailureMessageEditor';
import type { IClinic } from '@app/interfaces/clinic.interface';
import SuccessMessageEditor from '@app/pages/clinicPage/components/settingBlockMessageTab/components/SuccessMessageEditor';
import SuccessFeedbackMessageEditor from '@app/pages/clinicPage/components/settingBlockMessageTab/components/SuccessFeedbackMessageEditor';

interface Props {
  clinic: IClinic;
  setClinic: (clinic: IClinic) => void;
}

function SettingBlockMessageTab({ clinic, setClinic }: Props) {
  return (
    <>
      <WelcomeMessageEditor clinic={clinic} setClinic={setClinic} />

      <Divider sx={{ margin: '16px 0' }} />

      <SuccessFeedbackMessageEditor clinic={clinic} setClinic={setClinic} />

      <Divider sx={{ margin: '16px 0' }} />

      <SuccessMessageEditor clinic={clinic} setClinic={setClinic} />

      <Divider sx={{ margin: '16px 0' }} />

      <FailureMessageEditor clinic={clinic} setClinic={setClinic} />
    </>
  );
}

export default SettingBlockMessageTab;

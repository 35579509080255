import { makeAutoObservable } from 'mobx';
import type { IVisit } from '@app/interfaces/visit.interface';
import type { IDoctor } from '@app/interfaces/doctor.interface';

class VisitsPageStore {
  visit: IVisit | null = null;
  visits: IVisit[] = [];
  firstname: string = '';
  lastname: string = '';
  phone: string = '';
  comment: string = '';
  isEditVisitFormOpen: boolean = false;
  doctors: IDoctor[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setVisit(visit: IVisit | null) {
    this.visit = visit;
  }

  setVisits(visits: IVisit[]) {
    this.visits = visits;
  }

  setFirstname(firstname: string) {
    this.firstname = firstname;
  }

  setLastname(lastname: string) {
    this.lastname = lastname;
  }

  setPhone(phone: string) {
    this.phone = phone;
  }

  setComment(comment: string) {
    this.comment = comment;
  }

  setIsEditVisitFormOpen(isOpen: boolean) {
    this.isEditVisitFormOpen = isOpen;
  }

  setDoctors(doctors: IDoctor[]) {
    this.doctors = doctors;
  }

  setVisitsWithDoctors(visits: IVisit[]) {
    const extVisits = visits.map((visit: IVisit) => {
      const doctor = this.doctors.find((doctor) => doctor.id === visit.doctorid);

      return {
        ...visit,
        doctor,
      };
    });
    this.setVisits(extVisits);
  }

  addVisit(visit: IVisit) {
    this.setVisits([visit, ...this.visits]);
  }

  updateVisit(visit: IVisit) {
    const idx = this.visits.findIndex((v) => v.id === visit.id);

    if (idx !== -1) {
      this.setVisits([
        ...this.visits.slice(0, idx),
        Object.assign({}, this.visit, visit),
        ...this.visits.slice(idx + 1),
      ]);
    }
  }

  deleteVisit(visit: IVisit) {
    const updatedVisit = this.visits.filter(({ slug }) => visit.slug !== slug);
    this.setVisits(updatedVisit);
  }

  clearData() {
    this.setFirstname('');
    this.setLastname('');
    this.setPhone('');
    this.setVisit(null);
  }

  openEditForm() {
    this.setIsEditVisitFormOpen(true);
  }

  closeEditForm() {
    this.setIsEditVisitFormOpen(false);
  }
}

export default VisitsPageStore;

import React from 'react';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import globalAppStore from '@app/stores/globalAppStore';
import VisitsPageStore from '@app/stores/pages/visitsPageStore';

import { doPost } from '@app/api';
import { fetchDoctorsByClinic } from '@app/api/doctor';
import type { IClinic } from '@app/interfaces/clinic.interface';
import type { IOperator } from '@app/interfaces/operator.interface';
import type { IDoctor } from '@app/interfaces/doctor.interface';
import { API_RESPONSE_STATUS_CODE } from '@app/constants/common';
import { PHONE_MAX_NUMBERS } from '@app/constants/common';
import { NewVisitField } from '@app/constants/visit';

type NewVisitPropType = {
  store: VisitsPageStore;
  clinic?: IClinic;
  operator?: IOperator;
  date: Dayjs;
};

const AddVisitButton = observer(({ store, clinic, operator, date }: NewVisitPropType) => {
  const [doctors, setDoctors] = React.useState<IDoctor[]>();
  const [selectedDoctor, setSelectedDoctor] = React.useState<IDoctor>();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (store.isEditVisitFormOpen) {
      setSelectedDoctor(undefined);
    }
  }, [store.isEditVisitFormOpen]);

  React.useEffect(() => {
    if (store.isEditVisitFormOpen && clinic?.isProdoctorovDoctorFeedback) {
      fetchDoctorsByClinic(clinic.id).then((data: IDoctor[]) => {
        setDoctors(data);

        if (store?.visit?.doctorid) {
          const doctor = data.find((d) => d.id === store.visit?.doctorid);
          setSelectedDoctor(doctor);
        }
      });
    }
  }, [
    store.isEditVisitFormOpen,
    clinic?.id,
    clinic?.isProdoctorovDoctorFeedback,
    store?.visit?.doctorid,
  ]);

  const firstname =
    store.firstname || store?.visit?.firstname || store?.visit?.client?.firstname || '';

  const lastname = store.lastname || store?.visit?.lastname || store?.visit?.client?.lastname || '';

  const phone = store.phone || store?.visit?.phone || store?.visit?.client?.phone || '';

  const comment = store.comment || store.visit?.comment || '';

  const addVisit = async () => {
    const clinicId = globalAppStore.isAdmin ? clinic?.id : globalAppStore.loggedUser?.clinic?.id;

    const currentOperator = globalAppStore.isAdmin ? operator : globalAppStore.loggedUser;

    if (!clinicId || !currentOperator) {
      return;
    }

    const result = await doPost(`/api/clinics/${clinicId}/visit`, {
      visit: {
        firstname: firstname.trim(),
        lastname: lastname.trim(),
        phone: getPhone(phone),
        doctorid: selectedDoctor?.id || 0,
        visitedAt: date.format('DD.MM.YYYY'),
        comment: comment.trim(),
      },
      operator: currentOperator,
    });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      if (result.data.clinic.isProdoctorovDoctorFeedback && result.data.doctorid) {
        const doctor = store.doctors.find((d) => d.id === result.data.doctorid);
        if (doctor) {
          result.data.doctor = doctor;
        }
      }
      store.addVisit(result.data);
      store.clearData();
      handleClose();
    }
  };

  const updateVisit = async () => {
    if (!store.visit) {
      return;
    }

    setIsLoading(true);

    const result = await doPost(`/api/visit/${store.visit.slug}`, {
      firstname,
      lastname,
      phone: getPhone(phone),
      doctorid: selectedDoctor?.id || 0,
      comment: comment.trim(),
    });

    if (result.status === API_RESPONSE_STATUS_CODE.CREATED) {
      store.updateVisit(result.data);
      store.clearData();
      store.setIsEditVisitFormOpen(false);
    }

    setIsLoading(false);
  };

  const selectDoctor = (e: SelectChangeEvent) => {
    if (!doctors?.length) {
      return;
    }

    const doctor = doctors.find((doctor) => {
      return doctor.id.toString() === e.target.value.toString();
    });

    setSelectedDoctor(doctor);
  };

  const handleOpen = () => {
    store.setVisit(null);
    store.openEditForm();
  };

  const handleClose = () => store.closeEditForm();

  const isValidForm = () => firstname && lastname && isValidPhone(phone);

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleOpen}
        disabled={!!globalAppStore.isAdmin && (!clinic?.id || !operator?.id)}
      >
        Добавить пациента
      </Button>

      <Dialog open={store.isEditVisitFormOpen} onClose={handleClose}>
        <DialogTitle>Пациент</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus={true}
            margin='dense'
            id={NewVisitField.FIRSTNAME}
            label='Имя'
            fullWidth
            value={firstname}
            onChange={(e) => store.setFirstname(e.target.value)}
          />

          <TextField
            margin='dense'
            id={NewVisitField.LASTNAME}
            label='Фамилия'
            fullWidth
            value={lastname}
            onChange={(e) => store.setLastname(e.target.value)}
          />

          <FormControl variant='outlined' fullWidth sx={{ marginTop: '8px' }}>
            <InputLabel htmlFor='phone'>Телефон</InputLabel>
            <InputMask
              id='phone'
              mask='+7 999 999 9999'
              value={phone}
              onChange={(e) => store.setPhone(e.target.value)}
            >
              <OutlinedInput type='tel' label='Телефон' />
            </InputMask>
          </FormControl>

          {clinic?.isProdoctorovDoctorFeedback && doctors?.length && (
            <FormControl fullWidth sx={{ width: '100%', marginTop: '12px', marginBottom: '4px' }}>
              <InputLabel id='doctor-select'>Доктор</InputLabel>
              <Select
                labelId='doctor-select'
                id='doctor'
                label='Доктор'
                value={(selectedDoctor?.id || '').toString()}
                onChange={selectDoctor}
              >
                {doctors.map((doctor) => (
                  <MenuItem key={doctor.id} value={doctor.id} divider dense>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography>{doctor.fio}</Typography>
                      <Typography variant='caption' color='gray'>
                        {doctor.profession}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            margin='dense'
            id={NewVisitField.COMMENT}
            fullWidth
            multiline
            maxRows={3}
            minRows={2}
            value={comment}
            onChange={(e) => store.setComment(e.target.value)}
            label='Комментарий'
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={store.visit?.id ? updateVisit : addVisit}
            disabled={!isValidForm() || isLoading}
          >
            {store.visit?.id ? 'Сохранить' : 'Добавить'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
const getPhone = (phone: string): string => phone.replace(/\D/g, '');

const isValidPhone = (phone: string): boolean => getPhone(phone).length === PHONE_MAX_NUMBERS;

export default AddVisitButton;
